import React from 'react';

import {Link} from 'gatsby';
import SignInWithGoogle from '../components/SignIn/SiginWithGoogle';
import './signin.css';
import backButton from '../images/icons/back-button.svg';
import SignUpWithEmailForm from '../components/SignUp';

  export default () => (
	<div className="fullScreen">
        <div className="topBar">
            <div className="logoDiv">
				<Link to="/">
                	<span className="logo">Auto</span>
                	<span className="logo text-style-1">Save</span>
				</Link>
            </div>
        </div>
        <div className="signInScreen">
            <div className="headGroup">
				<Link to="/signInWithMobile">
					<img className="backButton" src={backButton} alt="back button" />
				</Link>
			    <p className="header headerWithBack">Create Account</p>
		    </div>
			<SignUpWithEmailForm />

            <div className="buttonWrap">
				<SignInWithGoogle />
				<div className="bottomText alreadyAccountDiv">
					<span className="newUser">Already have an account? </span>
					<Link className="aLink" to="/signin">
						<span className="newUser signUp">Sign in</span>
					</Link>
				</div>
			</div>
        </div>
    </div>
);
