import React from 'react';
import {navigate} from 'gatsby';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import '../../pages/signin.css';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
`;

const SignUpFormBase = (props) => {
	const initialState = {
		email: '',
		passwordOne: '',
		passwordTwo: '',
		isAdmin: false,
		error: null,
	};

	const [signUpCredrentials, setSignUpCredentials] = React.useState({...initialState});
	const {email, passwordOne, passwordTwo, isAdmin, error} = signUpCredrentials;
	const handleChange = (event) => {
		if (event.target.name === 'isAdmin') {
			setSignUpCredentials({
				...signUpCredrentials,
				[event.target.name]: event.target.checked,
			});
		} else {
			setSignUpCredentials({
				...signUpCredrentials,
				[event.target.name]: event.target.value,
			});
		}
	};
	const onSubmitForm = (event) => {
		const roles = {};
		if (isAdmin) {
			roles[ROLES.ADMIN] = ROLES.ADMIN;
		}
		if(passwordOne != passwordTwo) {
			var Error = { 
				message : "The password and confirmation password do not match."
			}
			setSignUpCredentials({
				...signUpCredrentials,
				error: Error,
			});
			event.preventDefault();
			return;
		}

		props.firebase
			.doCreateUserWithEmailAndPassword(email, passwordOne)
			.then((authUser) => {
				//Create a user in your Firebase realtime database
				return props.firebase.user(authUser.user.uid).set({
					email,
					roles,
				});
			})
			.then(() => {
				return props.firebase.doSendEmailVerification();
			})
			.then(() => {
				setSignUpCredentials({...initialState});
				navigate(ROUTES.MOBILE_VERFICATION);
			})
			.catch((Error) => {
				if (Error.code === ERROR_CODE_ACCOUNT_EXISTS) {
					Error.message = ERROR_MSG_ACCOUNT_EXISTS;
				}
				setSignUpCredentials({
					...signUpCredrentials,
					error: Error,
				});
			});

		event.preventDefault();
	};

	return (
		<div className="contentWrap">
			{error && <p className="errorMessage">{error.message}</p>}
			<form onSubmit={onSubmitForm}>
				<input
					className="textBox"
					value={email}
					onChange={handleChange}
					required
					type="email"
					name="email"
					autoComplete="email"
					id="email"
					placeholder="Email ID"
				/>

				<input
					className="textBox textBox1"
					value={passwordOne}
					onChange={handleChange}
					required
					type="password"
					id="passwordOne"
					name="passwordOne"
					autoComplete="current-passwordOne"
					placeholder="Password"
				/>

				<input
					className="textBox textBox1"
					type="password"
					value={passwordTwo}
					onChange={handleChange}
					required
					id="passwordTwo"
					name="passwordTwo"
					autoComplete="current-passwordTwo"
					placeholder="Confirm Password"
				/>
				<button className="buttonSignin" type="submit">
					<p className="inButtonText">Proceed</p>
				</button>
				
			</form>
		</div>
	);
};

const SignUpForm = withFirebase(SignUpFormBase);

export default SignUpForm;
